<template>
  <div
    data-testid="iconcomponent"
    class="icon-renderer ui-flex ui-justify-center ui-items-center"
    :class="color && color.length > 0 && 'icon-renderer--color'"
    v-html="getIcon(name, size, iconClass)"
  />
</template>

<script lang="ts" setup>
import type { IconProps } from "./types";
import { computed } from "vue";
import { getIcon } from "./getIcon";
import { toRefs } from "vue";

const props = defineProps<IconProps>();
const { name, size, color, className } = toRefs(props);

const computedSize = computed(() => `${size.value}px`);
const computedColor = computed(() => color.value || "currentColor");
const iconClass = computed(() => className.value || "");
</script>

<style scoped>
.icon-renderer {
  width: v-bind(computedSize);
  height: v-bind(computedSize);
}

.icon-renderer.icon-renderer--color {
  color: v-bind(computedColor);
}
</style>
